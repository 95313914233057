import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { API_TOKEN, API_URL } from 'constants/envs'
import { NewResponse, Response } from 'types/request'

export const newRequest = async <T>(options: AxiosRequestConfig): Promise<NewResponse<T>> => {
  try {
    const {
      data: { data, error, message, success }
    } = await axios(options)

    return { data, error, message, success }
  } catch (err) {
    const { response } = err as AxiosError

    if (response) {
      return response.data as NewResponse<T>
    }

    return {
      message: 'ApiError',
      success: false
    }
  }
}

/**
 * Make a request with the given options
 *
 * @param options
 */
const request = async (options: AxiosRequestConfig): Promise<Response> => {
  try {
    const { data } = await axios(options)
    return {
      data,
      success: true
    }
  } catch (err) {
    return {
      message: err?.response?.data || err,
      success: false
    }
  }
}

/**
 * Get the axios request config based on the given method, url, data & baseUrl
 *
 * @param method
 * @param url
 * @param data
 * @param baseUrl
 */
export const getAxiosRequestConfig = (
  method: 'GET' | 'PATCH' | 'POST',
  url: string,
  data: Record<string, any> | null = null,
  baseUrl: string | null = null
): AxiosRequestConfig => ({
  method,
  baseURL: baseUrl || API_URL,
  url,
  headers: {
    Authorization: `Basic ${API_TOKEN}`,
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  ...(data !== null && { data })
})

export default request
