import { RawStrapiList, RawStrapiTypeInsideData, UnknownObject } from 'types/cms-components'

/**
 * Helper function that flattens the structure of the strapi cms api response
 * @param {RawStrapiTypeInsideData<unknown>} res
 * @returns {{[p: string]: any, id: number} | null}
 */
export const flattenCmsResponse = (res?: RawStrapiTypeInsideData<UnknownObject>): { [p: string]: unknown; id: number } | null => {
  if (!res?.data) return null

  return {
    id: res.data.id || null,
    ...res.data.attributes
  }
}

/**
 * Helper function that flattens the localization structure and maps it into {slug, locale}
 * @param {RawStrapiList<[key: string]: unknown>} localizations
 * @returns {any[] | {locale: any, slug: any}[]}
 */
export const flattenCmsLocalizations = (localizations?: RawStrapiList<UnknownObject>) => {
  if (!localizations?.data?.length) return []

  return localizations.data.map(({ attributes }) => ({
    locale: attributes.locale as string,
    slug: attributes.slug as string
  }))
}
