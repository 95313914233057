import * as process from 'process'

export const API_TOKEN = process.env.NEXT_PUBLIC_API_TOKEN
export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const CMS_URL = process.env.NEXT_PUBLIC_CMS_URL
export const GMAPS_API_KEY = process.env.NEXT_PUBLIC_GMAPS_API_KEY
export const SIMULATION_DOWN = process.env.NEXT_PUBLIC_SIMULATION_DOWN === 'true'
export const REVALIDATE_SECRET = process.env.REVALIDATE_SECRET
export const MIXPANEL_ID = process.env.NEXT_PUBLIC_MIXPANEL_ID
export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string
export const CLOUDINARY_CLOUD_NAME = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME as string
