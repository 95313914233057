import Bugsnag from '@bugsnag/js'
import { Criticality, type IdentifierFormats, type Logging, Severity } from 'types/logging'

/**
 * Logs the error and metadata to Bugsnag
 * @param {Logging} paramsObject
 */
export const log = <T extends IdentifierFormats>({
  error,
  metaData,
  identifier,
  severity = Severity.ERROR,
  criticality = Criticality.MEDIUM
}: Logging<T>) => {
  const err = typeof error === 'string' ? new Error(error) : error

  Bugsnag.notify(err, (event) => {
    if (metaData) Object.keys(metaData).forEach((section) => event.addMetadata(section, metaData[section]))
    event.context = `[${criticality.toUpperCase()}] - ${identifier}`
    event.severity = severity
  })
}
