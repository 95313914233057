import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { CMS_URL } from 'constants/envs'
import { Language } from 'types/language'
import { ActiveVacanciesTransformed, GetProductsContentPayload, GetProductsContentResponse, ProductCmsReponse } from 'store/api/types'
import { convertProductsCmsResponseToDictionary } from 'utils/products'
import { RawStrapiList } from 'types/cms-components'

export const cmsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${CMS_URL}/api`
  }),
  endpoints: (builder) => ({
    // GET PRODUCERS
    getActiveVacancies: builder.query<ActiveVacanciesTransformed, void>({
      query: () => ({
        method: 'GET',
        url: 'job-offers?locale=all'
      }),
      transformResponse: ({ data }: { data: any[] }): ActiveVacanciesTransformed => {
        const normalizedJobOffers = data.map((offer) => ({
          ...offer.attributes,
          id: offer?.id ?? null
        }))

        return {
          fr: normalizedJobOffers.filter((vacancy) => vacancy.locale === Language.FRENCH).length,
          nl: normalizedJobOffers.filter((vacancy) => vacancy.locale === Language.DUTCH).length
        }
      }
    }),

    // GET PRODUCT CONTENT
    getProductContent: builder.query<GetProductsContentResponse, GetProductsContentPayload>({
      query: ({ language }) => ({
        method: 'GET',
        url: `products?locale=${language}`
      }),
      transformResponse: (res: RawStrapiList<ProductCmsReponse>) => convertProductsCmsResponseToDictionary(res)
    })
  }),
  reducerPath: 'cmsApi'
})

export const { useGetActiveVacanciesQuery, useGetProductContentQuery } = cmsApi
