import { Address } from 'components/form-fields/address-section/types'
import dayjs from 'dayjs'
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js'
import { store } from 'store'
import { SignChannel, SimulationType } from 'store/marketing/types'
import { Producer } from 'types/producer'
import {
  fileToBase64,
  getBoltGoPricesForApiRequests,
  getFixedPricesForApiRequests,
  getPricelistFromElectricityProducts,
  getPricelistFromGasProducts,
  removeSpacesAndDots
} from 'utils/helpers'
import { DATE_FORMAT } from './constants'
import { InvoiceMedium, Situation, StartDateRange } from './types'

import { getSimulatedPrice, getTotalSimulatedPrice } from 'utils/price'
import { AllProducts, ELProduct, NGProduct } from 'types/product-data'
import { MeterDetailsFieldKeys, SimulationFieldKeys } from 'store/customer/enums'
import { getElectricityProduct, getGasProduct } from 'utils/customerFlow'
import { ContactMode, CreateCustomerData, LegalForm } from 'types/customer'
import { InputGroups } from 'store/customer/types'
import { Region } from 'types/region'
import { CounterTypes } from 'features/simulation/types'

/**
 * Formats a given address to a string
 *
 * @param {Address} address
 * @param {[boolean]} singleLine
 * @returns
 */
export const addressToString = (address: Address, singleLine?: boolean): string => {
  return singleLine
    ? `${address.streetName} ${address.streetNumber}${address.streetBox ? ` ${address.streetBox}` : ''}, ${address.postalCode} ${
        address.townName
      }`
    : `${address.streetName} ${address.streetNumber} ${address.streetBox}<br />${address.postalCode} ${address.townName}`
}

/**
 * Formats a given date to a string in the dd/MM/yyyy format
 *
 * @param date
 * @returns
 */
export const dateToString = (date: string): string => {
  return dayjs(date).format(DATE_FORMAT)
}

/**
 * Returns the date range for the start date based on the given situation & isCompany parameters
 *
 * @param {Situation} situation
 * @returns {StartDateRange}
 */
export const getStartDateRange = (situation: Situation): StartDateRange => {
  const now = dayjs()

  switch (situation) {
    case Situation.NEW_CONNECTION: {
      return {
        initial: now.toISOString(),
        max: now.toISOString(),
        min: now.toISOString()
      }
    }

    case Situation.SWITCH: {
      return {
        initial: now.add(30, 'days').toISOString(),
        max: now.add(12, 'months').toISOString(),
        min: now.add(30, 'days').toISOString()
      }
    }

    case Situation.MOVE: {
      return {
        initial: now.toISOString(),
        max: now.add(10, 'months').toISOString(),
        min: now.subtract(25, 'days').toISOString()
      }
    }

    default:
      return {
        initial: now.toISOString(),
        max: now.toISOString(),
        min: now.toISOString()
      }
  }
}

/**
 * Checks if the given simulation type should show the price simulation section & relevant modals
 * @param {SimulationType} simulationType
 * @returns {boolean}
 */
export const checkHasPriceSimulationSection = (simulationType: SimulationType): boolean => {
  return [SimulationType.BOLT_GO, SimulationType.EXPRESS, SimulationType.PRICING_SIMULATION].includes(simulationType)
}

/**
 * Checks if the given simulation type should show edit usage drawer trigger & modal
 * @param {SimulationType} simulationType
 * @returns {boolean}
 */
export const checkHasEditUsageDrawer = (simulationType: SimulationType): boolean => {
  return [SimulationType.EXPRESS, SimulationType.PRICING_SIMULATION].includes(simulationType)
}

/**
 * Returns the registration & simulation data as a CreateCustomer object
 *
 * @param {AllProducts} allProducts
 * @param {Producer} producer
 * @returns {Promise<CreateCustomerData>}
 */
export const parseCreateCustomerData = async (producer: Producer, allProducts: AllProducts): Promise<CreateCustomerData> => {
  // Fetch data from the redux store
  const { opportunityId } = store.getState().app
  const { boltGoPrices, fixedPrices, inputs } = store.getState().customer
  const marketing = store.getState().marketing
  const { simulation, personalData, meterDetails, billing, overview } = inputs as InputGroups
  const { day, month, year } = personalData.dateOfBirth

  // Define the price object
  const price = getSimulatedPrice()

  // Define the products
  const electricityProduct = getElectricityProduct(allProducts)
  const gasProduct = getGasProduct(allProducts)

  return {
    boltGoPrices: getBoltGoPricesForApiRequests(boltGoPrices, inputs.simulation.chosenSimulationType, personalData.needsGas),
    ...(personalData.isCompany && {
      company: {
        enterpriseNumber: personalData.companyNumber ? removeSpacesAndDots(personalData.companyNumber) : '',
        legalForm: (personalData.legalForm as LegalForm) ?? null,
        name: personalData.companyName ?? '',
        vatApplication: personalData.subjectToVat ?? false
      }
    }),
    contact: {
      contactMode: billing.invoiceMedium === InvoiceMedium.DIGITAL ? ContactMode.EMAIL : ContactMode.POST,
      email: personalData.email,
      phoneMobile:
        (personalData.mobilePhone &&
          parsePhoneNumberFromString(
            personalData.mobilePhone,
            (personalData.mobilePhoneCountryCode ?? 'BE') as CountryCode
          )?.number?.toString()) ||
        ''
    },
    contractStartDate: dayjs(meterDetails.contractStartDate).format('YYYY-MM-DD'),
    correspondenceAddress: {
      ...(billing.sameCorrespondenceAddress ? personalData.deliveryAddress : billing.correspondenceAddress),
      countryCode: 'BE'
    },
    counterType: meterDetails.counterType,
    deliveryAddress: {
      ...personalData.deliveryAddress,
      countryCode: 'BE'
    },
    dynamicTariff:
      meterDetails[MeterDetailsFieldKeys.COUNTER_TYPE] === CounterTypes.DIGITAL &&
      simulation[SimulationFieldKeys.REGION] === Region.FLANDERS
        ? meterDetails.dynamicTariff
        : false,
    energyUsageGrade: simulation.usageGrade,
    fixedPrices: getFixedPricesForApiRequests(fixedPrices, inputs.simulation.chosenSimulationType),
    forcedAmount: simulation.energyGardenYearlyPromoAmount ? getTotalSimulatedPrice() : simulation.forcedAmount,
    hasSolarPanels: meterDetails.hasSolarPanels,
    invoicing: {
      ibanNumber: billing.iban ? removeSpacesAndDots(billing.iban) : '',
      paymentMethod: billing.paymentMethod
    },
    knowsMeterDetails: meterDetails.knowsMeterDetails,
    language: personalData.language,
    marketing: {
      agentID: marketing.agentID,
      callCampaign: marketing.callCampaign,
      callPartnerAccount: marketing.callPartnerAccount,
      campaignName: marketing.campaignName,
      eanCaptureType: meterDetails.eanCaptureType,
      emailConfirmationFlow: marketing.emailConfirmationFlow,
      eventID: marketing.eventID,
      eventName: marketing.eventName,
      gclid: marketing.gclid ?? undefined,
      noEanFlow: marketing.noEanFlow,
      nurtureFlow: marketing.nurtureFlow,
      partnerID: marketing.partnerID,
      sfUserAlias: marketing.sfUserAlias,
      shiftID: marketing.shiftID,
      signChannel: overview.chosenSignChannel,
      simulationSalesOffice: marketing.simulationSo,
      simulationType: inputs.simulation.chosenSimulationType,
      sourceType: marketing.sourceType
    },
    meterInfo: {
      electricity: meterDetails.knowsMeterDetails
        ? {
            EAN: meterDetails.electricity.ean?.toString() ?? '',
            exclEAN: meterDetails.electricity.eanExclNight?.toString() ?? '',
            indexDay:
              (simulation.usage.electricity.consumption.single
                ? meterDetails.electricity.total
                : meterDetails.electricity.day
              )?.toString() ?? '',
            indexExcl: meterDetails.electricity.exclNight?.toString() ?? '',
            indexNight: meterDetails.electricity.night?.toString() ?? '',
            meterNumber: meterDetails.electricity.meterNumber?.toString() ?? ''
          }
        : {
            EAN: '',
            exclEAN: '',
            indexDay: '',
            indexExcl: '',
            indexNight: '',
            meterNumber: ''
          },
      gas: meterDetails.knowsMeterDetails
        ? {
            EAN: meterDetails.gas.ean?.toString() ?? '',
            index: meterDetails.gas.total?.toString() ?? '',
            meterNumber: meterDetails.gas.meterNumber?.toString() ?? ''
          }
        : {
            EAN: '',
            index: '',
            meterNumber: ''
          },
      ...(meterDetails.meterReadingMonth && {
        meterReadingMonth: Number(meterDetails.meterReadingMonth)
      })
    },
    meterUsage: simulation.usage,
    opportunityId: opportunityId ?? '',
    person: {
      birthDate: day !== '' && month !== '' && year !== '' ? dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD') : null,
      firstName: personalData.firstName,
      lastName: personalData.lastName
    },
    ...(meterDetails[MeterDetailsFieldKeys.PREVIOUS_SUPPLIER] && {
      previousSupplier: meterDetails[MeterDetailsFieldKeys.PREVIOUS_SUPPLIER].supplier
    }),
    ...(meterDetails.previousInhabitant && {
      previousInhabitant: {
        firstName: meterDetails.previousInhabitant.firstName ? meterDetails.previousInhabitant.firstName : undefined,
        lastName: meterDetails.previousInhabitant.lastName ? meterDetails.previousInhabitant.lastName : undefined,
        supplier: meterDetails.previousInhabitant.supplier ? meterDetails.previousInhabitant.supplier : undefined,
        clientType: meterDetails.previousInhabitant.clientType,
        address: {
          streetName: meterDetails.previousInhabitant.address.streetName ? meterDetails.previousInhabitant.address.streetName : undefined,
          streetNumber: meterDetails.previousInhabitant.address.streetNumber
            ? meterDetails.previousInhabitant.address.streetNumber
            : undefined,
          streetBox: meterDetails.previousInhabitant.address.streetBox ? meterDetails.previousInhabitant.address.streetBox : undefined,
          postalCode: meterDetails.previousInhabitant.address.postalCode ? meterDetails.previousInhabitant.address.postalCode : undefined,
          townName: meterDetails.previousInhabitant.address.townName ? meterDetails.previousInhabitant.address.townName : undefined,
          townCode: meterDetails.previousInhabitant.address.townCode ? meterDetails.previousInhabitant.address.townCode : undefined
        }
      }
    }),
    price,
    priceListUrl: marketing.pricelistUrls ?? {
      electricity: getPricelistFromElectricityProducts(
        electricityProduct.details.code as ELProduct,
        allProducts,
        personalData.isCompany,
        personalData.language
      ),
      gas: getPricelistFromGasProducts(gasProduct.details.code as NGProduct, allProducts, personalData.isCompany, personalData.language)
    },
    producer: {
      id: producer.id,
      name: producer.name,
      salesforceId: producer.salesforceId,
      slug: producer.slug,
      sourceRegion: producer.energyRegion
    },
    products: {
      electricity: electricityProduct.details.code as ELProduct,
      gas: gasProduct.details.code as NGProduct
    },
    promoCode: simulation.promoCode,
    referralCode: simulation.referralCode,
    ...(overview.chosenSignChannel === SignChannel.SIGNATURE && { signatureDataUrl: overview.signature }),
    situation: meterDetails.situation,
    solarKva: simulation.solarKva,
    ...(meterDetails && meterDetails.upload && { uploadDataUrl: (await fileToBase64(meterDetails.upload[0])) as string }),
    ...(simulation[SimulationFieldKeys.REGION] === Region.WALLONIA &&
      meterDetails.counterType === CounterTypes.DIGITAL && {
        walloniaSolarPanelsSince2024: meterDetails.walloniaSolarPanelsSince2024
      })
  } satisfies CreateCustomerData
}
