import type { GetEnergyGardenResponse } from 'api/cms/energy-gardens/types'
import { Language } from 'types/language'

export enum EnergyGardens {
  BRUGSE_ZONNETUIN = 'brugse-zonnetuin'
}

export type EnergyGardenProduct = {
  _id: string
  energyGardenId: string
  name: string
  pricePerUnit: number
  totalQuantity: number
  createdAt: Date | string
  updatedAt: Date | string
  soldOut: boolean
  available: number
}

export type EnergyGardenOrder = {
  energyGardenId: string
  salesforceContactId?: string
  email: string
  status: EnergyGardenOrderStatus
  paidDate?: Date
  totalPrice: number
  mollieId?: string
  mollieCheckoutUrl?: string
  orderDetails: EnergyGardenOrderDetails[]
  createdAt: Date
  updatedAt: Date
}

export type EnergyGarden = {
  _id: string
  slug: string
  name: string
  createdAt: Date
  updatedAt: Date
  startDate: Date
  endDate: Date
  amountOfOrders: number
  products: EnergyGardenProduct[]
}

export type EnergyGardenResponse = {
  energyGarden: EnergyGarden
}

export type EnergyGardensResponse = {
  energyGardens: EnergyGarden[]
}

export type EnergyGardenProps = {
  energyGarden: EnergyGarden
}

export type LandingPageProps = {
  energyGarden: EnergyGarden
  content: GetEnergyGardenResponse['attributes']
  slug: string
}

export type EnergyGardenOrderDetails = {
  energyGardenProductId: string
  quantity: number
}

export type EnergyGardenOrderBody = {
  firstName: string
  lastName: string
  email: string
  mobilePhone: string
  language: Language
  orderDetails: EnergyGardenOrderDetails[]
  bankTransfer: boolean
}

export type EnergyGardenOrderResponse = {
  checkoutUrl?: string
  bankTransferDetails?: BankTransferDetails
  orderId: string
  totalPrice: number
}

export type BankTransferDetails = {
  bankName: string
  bankAccount: string
  bankBic: string
  transferReference: string
}

export enum EnergyGardenErrorCodes {
  ALREADY_ORDERED_AND_PAID = 'BOLT-EG-400-002'
}

export enum EnergyGardenOrderStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED'
}
