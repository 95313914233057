import { GetProductsContentResponse, ProductCmsReponse } from 'store/api/types'
import { flattenCmsResponse } from 'utils/cms'
import { ImageModel, RawStrapiList, WithId } from 'types/cms-components'
import { Product, ProductBillingOptions, ProductConfigOption, ProductContactOptions } from 'types/product-data'
import { PRODUCTS_CONFIG } from 'constants/products'
import { Region } from 'types/region'
import { store } from 'store'
import { SourceType } from 'types/marketing-params'

/**
 * Helper function that converts the cms response into a dictionary
 * @param {RawStrapiList<ProductCmsReponse>} res
 * @returns {GetProductsContentResponse}
 */
export const convertProductsCmsResponseToDictionary = ({ data }: RawStrapiList<ProductCmsReponse>): GetProductsContentResponse => {
  const normalizedProducts = data
    .map((product) => ({
      ...product.attributes,
      icon: flattenCmsResponse(product?.attributes?.icon) as WithId<ImageModel>,
      id: product?.id ?? null,
      slug: flattenCmsResponse(product?.attributes?.apiProduct)?.slug
    }))
    .filter((product) => product.slug)

  if (!normalizedProducts.length) return null

  return normalizedProducts.reduce((acc, product) => {
    acc[product.slug as Product] = product

    return acc
  }, {})
}

/**
 * Helper function that checks if a product is digital only
 * @param {Product} product
 * @returns {{[ProductConfigOption.BILLING_OPTIONS]: boolean, [ProductConfigOption.CONTACT_OPTIONS]: boolean}}
 */
export const checkIfProductIsDigitalOnly = (
  product?: Product
): { [ProductConfigOption.BILLING_OPTIONS]: boolean; [ProductConfigOption.CONTACT_OPTIONS]: boolean } => {
  if (!product) {
    return {
      [ProductConfigOption.CONTACT_OPTIONS]: false,
      [ProductConfigOption.BILLING_OPTIONS]: false
    }
  }

  const contactOptions = PRODUCTS_CONFIG[product][ProductConfigOption.CONTACT_OPTIONS]
  const billingOptions = PRODUCTS_CONFIG[product][ProductConfigOption.BILLING_OPTIONS]

  return {
    [ProductConfigOption.CONTACT_OPTIONS]: contactOptions.length === 1 && contactOptions[0] === ProductContactOptions.DIGITAL,
    [ProductConfigOption.BILLING_OPTIONS]: billingOptions.length === 1 && billingOptions[0] === ProductBillingOptions.DIGITAL
  }
}

/**
 * Helper function that returns the default products for a given region
 * @param {{
 *   region: Region
 *   isSales?: boolean
 *   withBoltGo?: boolean
 *   isEarlyBird?: boolean
 * }} payload
 * @returns {Product[]}
 */
export const getUsableProducts = ({
  region,
  withBoltGo = false,
  isEarlyBird = false
}: {
  region: Region
  withBoltGo?: boolean
  isEarlyBird?: boolean
}): Product[] => {
  const { sourceType, availableProducts } = store.getState().marketing

  // Always skip early bird products, they are replaced in the .reduce below
  const productsToSkip = [Product.EARLY_BIRD_OFFLINE, Product.EARLY_BIRD_ONLINE]

  // Add Bolt GO to skippable products
  if (!withBoltGo) productsToSkip.push(Product.GO)

  // Loop over products in the config and return the ones that are available in the given region
  return Object.keys(PRODUCTS_CONFIG).reduce((productsForGivenRegion: Product[], product: Product) => {
    const availableIn = PRODUCTS_CONFIG[product][ProductConfigOption.AVAILABLE_IN_REGIONS][sourceType]

    // Skip product if it's in the skippable list
    if (productsToSkip.includes(product)) return productsForGivenRegion

    // Skip the product for sales user if it's not in the available products list
    if (availableProducts && !availableProducts.includes(product)) return productsForGivenRegion

    // Add product if it's available in the given region
    if (availableIn.includes(region)) {
      const shouldChangeToEarlyBird =
        sourceType !== SourceType.PUSH && isEarlyBird && [Product.VARIABLE_OFFLINE, Product.VARIABLE_ONLINE].includes(product as Product)

      productsForGivenRegion.push(shouldChangeToEarlyBird ? convertProductToEarlyBird(product as Product) : (product as Product))
    }

    return productsForGivenRegion
  }, [])
}

/**
 * Helper function that converts a variable product to early bird product
 * @param {Product} product
 * @returns {Product}
 */
const convertProductToEarlyBird = (product: Product): Product => {
  switch (product) {
    case Product.VARIABLE_OFFLINE:
      return Product.EARLY_BIRD_OFFLINE
    case Product.VARIABLE_ONLINE:
      return Product.EARLY_BIRD_ONLINE
    default:
      return product
  }
}
