import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { API_TOKEN, API_URL } from 'constants/envs'
import { ProducerDictionary } from 'store/app/types'
import { AllProducts } from 'types/product-data'
import { PersonalizedProducersSimulationData, Producer } from 'types/producer'
import { isSoldOutWithCapacity } from 'utils/producers'
import { Language } from 'types/language'
import { PersonalizedProducersOffers } from 'store/customer/types'
import type { EnergyGardenResponse } from 'store/api/types'

export const boltApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    headers: {
      Accept: 'application/json',
      Authorization: `Basic ${API_TOKEN}`,
      'Content-Type': 'application/json'
    }
  }),
  endpoints: (builder) => ({
    getEnergyGardenBySlug: builder.query<EnergyGardenResponse | null, string>({
      query: (slug) => ({
        method: 'GET',
        url: `energy-gardens/${slug}`
      }),
      transformResponse: ({ success, data }) => {
        return success ? data.energyGarden : null
      }
    }),

    getPersonalizedOffer: builder.query<PersonalizedProducersOffers, PersonalizedProducersSimulationData>({
      query: (simulationData) => ({
        body: {
          expectedProducers: { nearest: true, popular: true, recent: true },
          ...simulationData
        },
        method: 'POST',
        url: 'producers/personalized'
      }),
      transformResponse: (response: any): PersonalizedProducersOffers => {
        const { popular: popularNL, nearest: nearestNL, recent: recentNL } = response[Language.DUTCH]
        const { popular: popularFR, nearest: nearestFR, recent: recentFR } = response[Language.FRENCH]

        return {
          [Language.DUTCH]: { nearest: nearestNL?.slug, popular: popularNL?.slug, recent: recentNL?.slug },
          [Language.FRENCH]: { nearest: nearestFR?.slug, popular: popularFR?.slug, recent: recentFR?.slug }
        }
      }
    }),

    getProducers: builder.query<ProducerDictionary, void>({
      query: () => ({
        method: 'GET',
        url: 'producers?enabled=true'
      }),
      transformResponse: (response: any): ProducerDictionary => {
        if (response.producers) {
          const filteredProducers = response.producers.filter((p: Producer) => !isSoldOutWithCapacity(p))
          return filteredProducers.reduce((acc: ProducerDictionary, producer: Producer) => {
            acc[producer.slug] = producer
            return acc
          }, {})
        }
      }
    }),

    getProducts: builder.query<AllProducts | null, void>({
      query: () => ({
        method: 'GET',
        url: 'products'
      }),
      transformResponse: ({ success, data }) => {
        return success ? data?.products : null
      }
    })
  }),
  reducerPath: 'boltApi'
})

export const { useGetProducersQuery, useGetProductsQuery, useGetPersonalizedOfferQuery, useGetEnergyGardenBySlugQuery } = boltApi
