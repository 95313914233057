import { ClientType } from 'types/customer'
import { EnergyUsageGrade, EstimatedUsages } from 'types/usages'

export const AVERAGE_CAPACITY_USAGE = 3500

// Estimates usages
// @remark: injection is now 80% of consumption
export const ESTIMATED_USAGES: EstimatedUsages = {
  [EnergyUsageGrade.LOW]: {
    // Equivalent of 1 person in House
    [ClientType.RESIDENTIAL]: {
      electricity: {
        consumption: 1_200,
        injection: 960,
        kva: 1.1
      },
      gas: 7_300
    },
    // Equivalent of Independent
    [ClientType.PROFESSIONAL]: {
      electricity: {
        consumption: 2_400,
        injection: 1_920,
        kva: 2.2
      },
      gas: 13_500
    }
  },
  [EnergyUsageGrade.AVERAGE]: {
    // Equivalent of 2 people in House
    [ClientType.RESIDENTIAL]: {
      electricity: {
        consumption: 2_350,
        injection: 1_880,
        kva: 2.1
      },
      gas: 13_500
    },
    // Equivalent of Office
    [ClientType.PROFESSIONAL]: {
      electricity: {
        consumption: 4_300,
        injection: 3_440,
        kva: 3.9
      },
      gas: 19_400
    }
  },
  [EnergyUsageGrade.HIGH]: {
    // Equivalent of 4 people in House
    [ClientType.RESIDENTIAL]: {
      electricity: {
        consumption: 4_300,
        injection: 3_440,
        kva: 3.9
      },
      gas: 16_700
    },
    // Equivalent of Shop
    [ClientType.PROFESSIONAL]: {
      electricity: {
        consumption: 13_000,
        injection: 10_400,
        kva: 10
      },
      gas: 33_600
    }
  }
}
