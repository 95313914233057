import { FetchProducers, ParentProducer, Producer } from 'types/producer'
import { filterAndSortProducers } from 'utils/producers'
import request, { getAxiosRequestConfig } from 'utils/request'
import { stringify } from 'qs'

/**
 * Fetches the producers from the API
 *
 * @param {FetchProducers} filters
 * @returns {Promise<Producer[]>}
 */
export const fetchProducers = async ({ language, params }: FetchProducers): Promise<Producer[]> => {
  const query = stringify(params, { addQueryPrefix: true, skipNulls: true })
  const { success, data } = await request(getAxiosRequestConfig('GET', `producers${query}`))

  return success ? filterAndSortProducers({ hideSoldOut: params?.soldOut === false, language, producers: data.producers }) : []
}

/**
 * Fetches the producers for the showcase (featured producers or the first 4 producers if there are no featured ones)
 *
 * @param {FetchProducers} filters
 * @returns {Promise<Producer[]>}
 */
export const fetchProducersToShowcase = async ({ language }: FetchProducers): Promise<Producer[]> => {
  const params = { enabled: true, featured: true, soldOut: false }

  const producers = await fetchProducers({ language, params })

  let producersToShow = producers
    .filter((prod: Producer) => prod.isFeatured[language])
    .sort((p1: Producer, p2: Producer) => (p1.order < p2.order ? -1 : p1.order > p2.order ? 1 : 0))
    .slice(0, 4)

  if (!producersToShow?.length) {
    const allProducers = await fetchProducers({ language, params })
    producersToShow = allProducers.slice(0, 4)
  }

  return producersToShow
}

/**
 * Fetches the producer slugs from the API
 *
 * @param {FetchProducers} filters
 * @returns {Promise<Producer[]>}
 */
export const fetchProducersSlugs = async ({ params }: FetchProducers): Promise<string[]> => {
  const query = stringify(params, { addQueryPrefix: true, skipNulls: true })
  const { success, data } = await request(getAxiosRequestConfig('GET', `producers/slugs${query}`))

  return success ? data.slugs : []
}

/**
 * Fetches the producer by slug
 *
 * @param {string} slug
 * @param {boolean} [withExtraImages]
 * @param {boolean} [full]
 * @returns Promise<Producer>
 */
export const fetchProducerBySlug = async (slug: string, withExtraImages?: boolean, full?: boolean): Promise<Producer> => {
  const query = stringify({ full, withExtraImages }, { addQueryPrefix: true, skipNulls: true })
  const { success, data } = await request(getAxiosRequestConfig('GET', `producers/${slug}${query}`))

  return success ? data.producer : null
}

/**
 * Sends the producer request to the API
 *
 * @param data
 * @returns
 */
export const producerRequest = async (data): Promise<boolean> => {
  const { success } = await request(getAxiosRequestConfig('POST', 'producers/request', data))

  return success
}

/**
 * Fetches the parent producers from the API
 * @param {string} slug
 * @param {boolean} withExtraImages
 * @param {boolean} full
 * @returns {Promise<ParentProducer>}
 */
export const fetchParentProducerBySlug = async (slug: string, withExtraImages?: boolean, full?: boolean): Promise<ParentProducer> => {
  const query = stringify({ full, withExtraImages }, { addQueryPrefix: true, skipNulls: true })
  const { success, data } = await request(getAxiosRequestConfig('GET', `producers/parents/${slug}${query}`))

  return success ? data.producer : null
}

/**
 * Fetches the producer slugs from the API
 *
 * @param {FetchProducers} filters
 * @returns {Promise<Producer[]>}
 */
export const fetchParentProducersSlugs = async ({ params }: FetchProducers): Promise<string[]> => {
  const query = stringify(params, { addQueryPrefix: true, skipNulls: true })
  const { success, data } = await request(getAxiosRequestConfig('GET', `producers/parents/slugs${query}`))

  return success ? data.slugs : []
}
