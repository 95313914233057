import { BankTransferDetails, EnergyGardenOrderResponse, EnergyGardens } from 'features/energy-gardens/types'
import { BillingInputs, PersonalDataInputs } from 'store/customer/types'
import { BillingFieldKeys, PersonalDataFieldKeys } from 'store/customer/enums'

type EnergyGardenState = {
  currentView: EnergyGardenFlowViews
  inputs: EnergyGardenOrderInputs
  bankTransferDetails?: BankTransferDetails & Pick<EnergyGardenOrderResponse, 'orderId' | 'totalPrice'>
}

export type EnergyGardenStore = {
  [key in EnergyGardens]: EnergyGardenState
}

type ContactData = {
  address: PersonalDataInputs['deliveryAddress']
  acceptedTerms: boolean
} & Pick<
  PersonalDataInputs,
  | PersonalDataFieldKeys.FIRSTNAME
  | PersonalDataFieldKeys.LASTNAME
  | PersonalDataFieldKeys.EMAIL
  | PersonalDataFieldKeys.REPEAT_EMAIL
  | PersonalDataFieldKeys.MOBILE_PHONE
  | PersonalDataFieldKeys.MOBILE_PHONE_COUNTRY_CODE
> &
  Partial<Pick<BillingInputs, BillingFieldKeys.IBAN>>

export type EnergyGardenOrderInputs = {
  contactData: ContactData
  isAlreadyCustomer: boolean
  products: Partial<{
    [key: string]: number
  }>
  skipCustomer: boolean
}

// REDUCERS

type DefaultPayload = {
  garden: EnergyGardens
}

export type SetCurrentViewPayload = DefaultPayload & {
  view: EnergyGardenOrderViews
}

export type UpdateEnergyGardenInputsPayload = DefaultPayload & {
  inputs: Partial<EnergyGardenOrderInputs>
}

export type SetBankTransferDetailsPayload = DefaultPayload & BankTransferDetails & Pick<EnergyGardenOrderResponse, 'orderId' | 'totalPrice'>

// ENUMS

export enum EnergyGardenOrderViews {
  POSTAL_CODE = 'postalCode',
  INITIATE_CUSTOMER = 'initiateCustomer',
  CHOOSE_PRODUCT = 'chooseProduct',
  INITIATE_PAYMENT = 'initiatePayment',
  BANK_TRANSFER = 'bankTransfer'
}

export enum EnergyGardenConfirmationViews {
  FINAL_CONFIRMATION = 'finalConfirmation',
  EXISTING_CUSTOMER_INFO = 'existingCustomerInfo',
  NEW_CUSTOMER_INFO = 'newCustomerInfo',
  START_REGISTRATION = 'startRegistration'
}

export type EnergyGardenFlowViews = EnergyGardenOrderViews | EnergyGardenConfirmationViews
